<template>
  <div
    style="margin-top: 0px;"
    @click="removeLists()"
  >
    <div>

      <div
        class="table__header "
        style="margin-bottom: 15px !important; justify-content: flex-start;"
      >
        <div>
          <button
            v-b-modal.modal-new-location
            class="button"
          >
            {{ $t('Add Location') }}
          </button>
        </div>
        <div
          class="tabela"
          style="display: flex; flex-direction: row; justify-content: space-evenly; width: 17%; margin-left: 15px;"
        >
          <b-form-group
            id="input-group-1"
            label-for="input-1"
          >
            <vue-select
              v-model="locationtype"
              style="width: 290px;"
              :options="getLocationTypes"
              :reduce="(e) => e.value"
              :placeholder="$t('SelectLocation')"
              label="label"
            />
          </b-form-group>

          <!-- <b-form-group
            id="input-group-1"
            :label="`${$t('Select locations by type')}:`"
            label-for="input-1"
          >
            <vue-select
              v-model="locationByType"
              style="width: 250px;"
              :options="getLocationsByType"
              :reduce="(e) => e.locationId"
              :placeholder="$t('Select')"
              label="locationName"
            />
          </b-form-group> -->
        </div>
        <div
          class="filtering"
        >
          <div class="black">
            <b-icon-search
              v-if="searchLocation == ''"
              class="search1"
              style="position: absolute; left:5px; top: 12px"
            />
            <b-icon-x-circle
              v-else-if="searchLocation != '' && !noSearch"
              class="search1"
              style="position: absolute; right: 20px; top: 12px; cursor: pointer"
              @click="searchLocation = ''"
            />
            <input
              v-model="searchLocation"
              autocomplete="off"
              type="text"
              name="search-box"
              :placeholder="`${$t('Location')} `"
              :style="getFilterLocation.length > 0 && noSearch ? 'border-radius: 8px 8px 0px 0px' : ''"
              @keyup="inputChanged"
              @keydown.down="onArrowDown"
              @keydown.up="onArrowUp"
            >
          </div>
          <div
            v-if="getFilterLocation.length > 0 && noSearch"
            ref="scrollContainer"
            class="dropdrop"
            :style="getFilterLocation.length > 7 ? 'height: 210px' : 'height: auto'"
          >
            <div
              v-for="(result, index) in getFilterLocation"
              :key="index"
              ref="options"
              style="cursor: pointer"
              class="search-item"
              :class="{ 'is-active': index === arrowCounter }"
              @click="searchByOn(result); noSearch = false"
            >
              <p style="margin: 0">
                {{ result.locationName }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div
        style="width: 100%;padding-top: 16px; margin-top: 15px; height: 78vh; overflow-y: scroll;"
        class="scroll"
      >
        <table
          ref="exportable_table"
          class="team_table"
        >
          <thead style="position: sticky; top: -18px">
            <tr>
              <th>
                {{ $t("No") }}
              </th>
              <th>
                {{ $t('Name') }}
              </th>
              <th>
                {{ $t('Type') }}
              </th>
              <th>
                {{ $t('Notes') }}
              </th>
              <th>
                {{ $t('Items') }}
              </th>
              <th>
                {{ $t('Actions') }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(item, index) in getLocationByPagination"
              :key="item.locationId"
              class="hover-row"
            >
              <!-- @click="matProps(supplier)" -->
              <td>
                {{ getLocationByPaginationTotalItems - (page - 1) * pageSize - index }}.
              </td>
              <td>
                {{ item.locationName }}
              </td>
              <td>
                {{ $t(item.locationType) }}
              </td>
              <td>
                <button
                  v-b-modal.location-notes
                  class="editButton"
                  @click="notesReview(item)"
                >
                  {{ $t('Notes') }}
                </button>
              </td>
              <td>
                {{ item.items }}
              </td>
              <td
                v-b-modal.modal-edit-location
                @click="editItem(item)"
              >

                <!-- v-b-modal.modal-edit -->
                <p
                  class="p-0 m-0 ml-50"
                >
                  <button class="editButton">
                    {{ $t('Edit') }}
                  </button>
                </p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div>
        <b-pagination
          v-if="getLocationByPaginationTotalItems > 15"
          v-model="page"
          :total-rows="getLocationByPaginationTotalItems"
          :per-page="pageSize"
          first-number
          last-number
          align="fill"
          prev-class="prev-itemi"
          next-class="next-itemi"
          class="mt-1 mb-0"
        >
          <template #prev-text>
            <span class="previousOrNext"><b-icon-arrow-left />{{ $t('Previous') }}</span>
          </template>
          <template #next-text>
            <span class="previousOrNext">{{ $t('Next') }}<b-icon-arrow-right /></span>
          </template>
        </b-pagination>
      </div>
    </div>

    <AddLocation @location="addLocationn" />
    <EditLocation
      :edit-supp="editLocationn"
      @edit="editLoc"
    />
    <LocationNotes :edit-supp="editLocationn" />
    <!-- <EditRowModal
      :edit-supp="editSupp"
      @edit="editSup"
    /> -->
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
// import AddSupplierModal from '@/modals/AddSupplierModal.vue';
// import EditRowModal from '@/modals/EditRowModal.vue';
import AddLocation from './modals/AddLocation.vue';
import EditLocation from './modals/EditLocation.vue';
import LocationNotes from './modals/LocationNotes.vue'
// import { countryCallingCodes } from '../countryCodes'

/*  eslint-env es6  */
export default {
  components: {
    AddLocation,
    EditLocation,
    LocationNotes,
    // EditRowModal,
  },
  // props: {
  //   materialId: {
  //     type: String,
  //     default: '',
  //   },
  // },
  data() {
    return {
      // countryCallingCode: countryCallingCodes,
      searchLocation: '',
      noSearch: false,
      toggleSarch: false,
      show: true,
      searchCN: '',
      searchON: '',
      // noSearchTwo: false,
      searchByContactName: '',
      searchBySupplierName: '',
      locationtype: null,
      locationByType: null,
      page: 1,
      pageSize: 15,
      editLocationn: {
        // locationId: '',
        locationName: '',
        locationType: '',
        notes: '',
      },
      deactivateItemId: {},
      totalItems: '',
      rows: [],
      arrowCounter: 0,
      enterPressed: false,
      getLocationTypes: [
        { value: 'WarehouseForClient', label: this.$t('WarehouseForClient') },
        { value: 'WarehouseForProductInStock', label: this.$t('WarehouseForProductInStock') },
        { value: 'WarehouseForMaterialVariants', label: this.$t('WarehouseForMaterialVariants') },
        { value: 'Showroom', label: this.$t('Showroom') },
        { value: 'Shop', label: this.$t('Shop') },
        { value: 'MainWarehouse', label: this.$t('MainWarehouse') },
      ],
    };
  },
  computed: {
    ...mapGetters([
      'getLanguage',
      'getSuppliers',
      'getCurrentPageForSuppliers',
      'getTotalItemsForSuppliers',
      'getLoggedInUser',
      // 'getFilteredSuppliersBySN',
      'getFilteredSuppliersByCN',
      'getLocation',
      // 'getLocationTypes',
      'getLocationsByType',
      'getLocationByPagination',
      'getLocationByPaginationTotalItems',
      'getFilterLocation',
      'getResetLocation',
    ]),
  },
  watch: {
    page(value) {
      if (this.searchLocation == '') {
        this.locationPagination({
        // locationId: this.locationId,
          locationType: this.locationtype,
          pageNumber: value,
          pageSize: this.pageSize,
        });
      }
    },
    searchLocation(value) {
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      this.timeoutId = setTimeout(() => {
        if (value === '') {
          this.removeLists();
          this.locationPagination({
            locationId: null,
            locationType: this.locationtype,
            pageNumber: this.page,
            pageSize: this.pageSize,
          });
        } else if (this.enterPressed) {
        // eslint-disable-next-line no-useless-return
          return;
        } else {
        // Set locationId to null explicitly when searching
          this.locationId = null;
          this.filterLocationBy(value);
          this.noSearch = true;
        }
      }, 500)
    },
    locationtype(value) {
      console.log(value)
      // this.locations_by_type(value)
      this.locationPagination({
        locationId: null,
        locationType: value,
        pageSize: this.pageSize,
        pageNumber: this.page,
      })
    },
  },
  mounted() {
    this.loadLocationTypes()

    this.fetch()
  },
  methods: {

    ...mapActions([
      'loadSuppliers',
      'addSupplier',
      'editSupplier',
      'getSuppliersCompanyNameLikeORContactNameLike',
      'deactivateSupplierById',
      'changeLoadingtoTrue',
      'resetFilteredSupplierBySN',
      'filterSuppliersByCN',
      'resetFilteredSupplierByCN',
      'addLocation',
      'editLocation',
      'locationi',
      'loadLocationTypes',
      'locations_by_type',
      'locationPagination',
      'filterLocationBy',
      'resetLocationBy',
    ]),
    async searchByOn(value) {
      this.searchLocation = value.locationName
      // this.locationId = value.locationId
      this.noSearch = false
      await this.locationPagination({
        locationId: value.locationId,
        locationType: this.locationtype,
        pageNumber: 1,
        pageSize: this.pageSize,
        successCallback: () => {
          // this.$router.push('/order-item-tracking')
        },
      })
      // this.checkIfArticleNumWasNull()
    },
    addLocationn(value) {
      this.addLocation({
        object: value,
        successCallback: () => {
          this.locationPagination({
            locationType: this.locationType,
            pageNumber: this.page,
            pageSize: this.pageSize,
          })
        },
      });
    },
    async fetch() {
      try {
        await this.locationPagination({
          locationType: this.locationType,
          pageNumber: this.page,
          pageSize: this.pageSize,
        })
          .then((res) => {
            console.log(res);
            // this.changeLoadingtoTrue(true)
          })
      } catch (e) {
        console.log(e);
        // this.changeLoadingtoTrue(false)
      }
    },
    onArrowDown(ev) {
      ev.preventDefault()
      if (this.arrowCounter < this.getLocationByPagination.length - 1) {
        this.arrowCounter += 1;
        this.fixScrolling();
      }
    },

    onArrowUp(ev) {
      ev.preventDefault()
      if (this.arrowCounter > 0) {
        this.arrowCounter -= 1;
        this.fixScrolling()
      }
    },


    inputChanged(event) {
      if (event.code == 'ArrowUp' || event.code == 'ArrowDown') { return; }
      // this.getFilteredOrdersbyON = [];

      // eslint-disable-next-line no-useless-return
      if (event.code == 'Enter') {
        this.enterPressed = true
        this.searchByOn(this.getFilterLocation[this.arrowCounter])
        this.removeLists()
        this.arrowCounter = 0
        setTimeout(() => {
          this.enterPressed = false
        }, 1000)
        // eslint-disable-next-line no-useless-return
        return;
      }
    },
    fixScrolling() {
      const liH = this.$refs.options[this.arrowCounter].clientHeight;
      console.log(liH)
      this.$refs.scrollContainer.scrollTop = liH * this.arrowCounter;
    },
    editItem(item) {
      this.editLocationn = item;
    },
    notesReview(item) {
      this.editLocationn = item.notes;
    },
    async editLoc(obj) {
      await this.editLocation({
        object: obj,
        successCallback: () => {
          this.locationPagination({
            locationType: this.locationType,
            pageNumber: this.page,
            pageSize: this.pageSize,
          })
        },
      });
    },
    deactivate(obj) {
      this.deactivateItemId = obj;
    },
    removeLists() {
      this.noSearch = false;
      this.resetLocationBy();
    },
  },
};
</script>

<style scoped lang="scss">

.team_table {
  width: 100%;
  font-size: 12px;
}
.team_table td {
  padding: 11px 9px !important;
}

.team_table tr {
  border-bottom: 1px solid #dcdfe6;
  color: #475467;
}
.hover-row:hover {
    background-color: rgba(255, 39, 79, 0.1);
}
.active2 {
  font-weight: bold;
  color: $base-color;
}

@media screen and (max-width:1700px) {
.tabela{
  width: 28% !important;
}

}
@media screen and (max-width: 1200px) {
  .tabela{
  width: 30% !important;
}
  .scroll {
    overflow-x: scroll;
  }

}

.dropdrop::-webkit-scrollbar {
  display: none;

}

.dropdrop {
  display: inline-block;
  overflow: auto;
  position: absolute;
  background: white;
  width: 227px;
  box-shadow: none;
}

input[name="search-box"] {
  display: block;
  max-width: 500px;
  width: 100%;
  padding: 0.5rem 1rem;
  padding-left: 25px;
}
.search-item {
  padding: 5px;
  max-width: 500px;
  width: 100%;
  font-weight: 400;
  color: #82868c;
  font-size: 1rem;
  border-bottom: 1px solid #e8e8e8;
}

.search-item:last-child {
  border-bottom: none;
}

.search-item:hover {
  background: #82868c;
  color: white;
}

.search-item hr {
  color: lightgray;
  border-top: none;
  margin: -1.3rem 0 0.5rem 0;
}

.is-active {
  background-color: #dedede;
}
</style>
